import Button from "../../components/QuizButton/Button";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";


export default function ViewBankAccounts({
  fetchAllUsersAddedAccount,
  userName,
  setEdit,
  key,
  setActive
}) {
  const handleEdit = (id) => {
    setActive('Add-Account')
    setEdit(true);
    
  };

  return (
    <div className="viewAccountCtns" key={key}>
      <div className="viewEditCtn">
        <div className="ViewAccountCtn">
          <p className="BankOwnersName">{userName}</p>
          <p className="BankDetails">
            {fetchAllUsersAddedAccount?.data?.account?.account_number} -
            <span>{fetchAllUsersAddedAccount?.data?.account?.bank_name}</span>
          </p>
        </div>
        <div
          className="viewEditIconCtn"
          onClick={() =>
            handleEdit(fetchAllUsersAddedAccount?.data?.account?.id)
          }
        >
          <ModeEditOutlinedIcon className="vieweditIcon" />
        </div>
      </div>
      <div className="viewAcctBtn">
        <Button
          type="submit"
          btntype="active"
          //   handleclick={() => HandleBankAccount()}
        >
          Update Account
        </Button>
      </div>
    </div>
  );
}
