import React, { useEffect } from "react";
import ConfettiImage from "react-confetti";
function Confetti({ playSound }) {
  useEffect(() => {
    playSound();
  }, []);

  return <ConfettiImage />;
}

export default Confetti;
