import { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useCreateWallet, useFetchWalletBalance, useFundWallet } from "../../hooks/wallet/useWallet";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useWalletLogic = () => {
  const navigate=useNavigate()
  const ccAuth = JSON.parse(sessionStorage.getItem("ccAuth")|| "")
  const walletBalance = useFetchWalletBalance(ccAuth?.id)
  const createWallet= useCreateWallet()
  const fundWallet = useFundWallet()

  const handleCreateWallet = (pin) => {
   
    createWallet.mutate({ user_id: ccAuth?.id, pin }, {
      onSuccess: (response) => {
        // console.log({response})
        toast.dismiss()
        toast.success(response?.message)
        walletBalance.refetch()
      },
      onError: (err) => {
        toast.dismiss()
        toast.error(err?.response?.data?.message)
        if (err?.response?.data?.message==="You dont have pin") {
          navigate(`/qwiz/security`)
        }
      }
    })
  }
  

  const handleFundWallet = (amount,email) => {
    toast.loading(`Please Wait`)
    return fundWallet.mutate({ user_id: ccAuth?.id, amount, email }, {
      onSuccess: (data) => {
        window.location.href=data?.authorization_url
        console.log({data})
      },
      onError: (err) => {
        toast.dismiss()
        toast.error(err?.response?.data?.message)
      }
    })
  }

    const [config, setConfig] =useState( {
        publicKey: process.env.REACT_APP_PAYSTACK_KEY|| 'pk_test_cef838d489a30d84ad5b9cd52f781b0c4ae08547',
    });
    
    // you can call this function anything
    const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
        console.log(reference);
    
    };
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }

  
    const initializePayment = usePaystackPayment(config);
    return {initializePayment, setConfig, walletBalance, handleFundWallet, handleCreateWallet}
    
}

export default useWalletLogic