import React, { createContext, useContext, useState } from "react";
import { useMutation } from "react-query";
import { request } from "../../../hooks/api/fetch";

// Create Context
const CourseModuleContext = createContext();

// Provider Component
export const CourseModuleProvider = ({ children }) => {
  const [courseModules, setCourseModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

 

  return (
    <CourseModuleContext.Provider value={{ courseModules,setCourseModules, loading, error }}>
      {children}
    </CourseModuleContext.Provider>
  );
};

// Custom Hook for Using Context
export const useCourseModule = () => useContext(CourseModuleContext);
