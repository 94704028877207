import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/QuizButton/Button";
import Modal from "../../components/modal/Modal";
import AuthLayout from "../../components/layout/AuthLayout";
import {
  useSetPin,
  useChangePin,
  useConfirmPinStatus,
} from "../../hooks/api/post";
import SetPin from "./SetPin";
import ChangePIN from "./ChangePin";
import { toast } from "react-toastify";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import successImage from "../../assets/images/login-image.png";
import useWalletLogic from "../wallet/useWalletLogic";

export default function Security() {
  const getUserID = sessionStorage.getItem("ccAuth");
  const userID = JSON.parse(getUserID);
  const [active, setActive] = useState("create");
  const [key, setKey] = useState("");
  const [activateEditPin, setActivateEditPin] = useState(false);
  const [success, setSuccess] = useState(false);
  const createPin = useSetPin();
  const updatePin = useChangePin();
  const confirmUsersPinStatus = useConfirmPinStatus();
  const navigate = useNavigate();
  const [createUserPin, setCreateUserPin] = useState({
    pin: "",
    confirmPin: "",
  });
  const [pinUpdate, setPinUpdate] = useState({
    confirmChangedPin: "",
    newPin: "",
    currentPin: "",
  });

  const { handleCreateWallet } = useWalletLogic();
  const handleResetState = () => {
    setPinUpdate({
      confirmChangedPin: "",
      newPin: "",
      currentPin: "",
    });
  };
  const handleCreatePinReset = () => {
    setCreateUserPin({
      pin: "",
      confirmPin: "",
    });
  };

  // HANDLE CLOSE Modal
  function handleCloseModal() {
    setSuccess(false);
    navigate(`/qwiz/homepage`);
  }

  // RETURN TO HOME
  const ReturnToHome = () => {
    navigate("/qwiz/homepage");
  };

  //CHECK IF USER ALREADY CREATED A PIN ELSE NAVIGATE TO SECURITY
  useEffect(() => {
    const checkUserPinStatus = async () => {
      try {
        const response = await confirmUsersPinStatus.mutateAsync({
          user_id: userID.id,
        });
        if (response?.message !== "Pin Set") {
          setActivateEditPin(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkUserPinStatus();
  }, []);

  //HANDLE ACTIVE BUTTON
  const HandleActive = (buttonid) => {
    setActive(buttonid);
  };

  // HANDLE CREATE PIN
  async function handleCreatePin() {
    if (
      createUserPin?.pin === createUserPin?.confirmPin &&
      createUserPin?.pin !== ""
    ) {
      const response = createPin.mutate(
        {
          user_id: userID.id,
          pin: createUserPin?.pin,
        },
        {
          onSuccess: () => {
            setSuccess(true);
            handleCreatePinReset();
            handleCreateWallet(createUserPin?.pin);
          },
          onError: (error) => {
            toast.dismiss();
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_LEFT,
            });
          },
        }
      );
    } else {
      toast.dismiss();
      toast.error("PIN does not match", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  // HANDLE UPDATE PIN
  async function handleChangePin() {
    if (
      pinUpdate?.newPin === pinUpdate?.confirmChangedPin &&
      pinUpdate?.newPin !== ""
    ) {
      const response = updatePin.mutate(
        {
          user_id: userID.id,
          old_pin: pinUpdate?.currentPin,
          new_pin: pinUpdate?.newPin,
        },
        {
          onSuccess: () => {
            setSuccess(true);
            handleResetState();
          },
          onError: (error) => {
            toast.dismiss();
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_LEFT,
            });
          },
        }
      );
    } else {
      toast.dismiss();
      toast.error("PIN does not match", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <AuthLayout>
      <main className="paymentCtn">
        <h1 className="paymentHeading">Security</h1>
        {active === "create" ? (
          <p className="subscriptionInfo">
            {" "}
            Set Your Secure PIN for your Wallet{" "}
          </p>
        ) : (
          <p className="subscriptionInfo">
            {" "}
            To Update Your Secure PIN, Input Your Current PIN and Set a New PIN{" "}
          </p>
        )}
        <div className="subscriptionAct">
          <Button
            type="submit"
            handleclick={() => HandleActive("create")}
            btntype={`${active === "create" ? "planActive" : "plan"}`}
          >
            Create Pin
          </Button>
          <Button
            type="submit"
            handleclick={() => HandleActive("change")}
            btntype={
              activateEditPin
                ? "smallBtnDisabled"
                : active === "change"
                ? "planActive"
                : "plan"
            }
            disabled={activateEditPin}
          >
            Change Pin
          </Button>
        </div>
        {active === "create" ? (
          <SetPin
            setPinText="Set New PIN"
            confirmPinText="Confirm PIN"
            createPinText="Create PIN"
            next="Next"
            active={active}
            createUserPin={createUserPin}
            setCreateUserPin={setCreateUserPin}
            key={key}
            handleCreatePin={handleCreatePin}
            createPin={createPin}
          />
        ) : (
          <ChangePIN
            setPinText="Enter Current PIN"
            EnterNewPinText="Enter new PIN"
            confirmNewPinText="Confirm New PIN"
            next="Next"
            createNewPinText="Update PIN"
            active={active}
            pinUpdate={pinUpdate}
            setPinUpdate={setPinUpdate}
            key={key}
            handleChangePin={handleChangePin}
            updatePin={updatePin}
          />
        )}

        <Modal open={success} handleClose={handleCloseModal}>
          <div className="successModal">
            <h3 className="viewAccText">
              {updatePin?.isSuccess
                ? "PIN Changed successfully"
                : "PIN Created successfully"}
            </h3>
            <img src={successImage} alt="success image" className="imgStyle" />
            <div className="modalBtnCtn">
              <Button type="submit" btntype="modal" handleclick={ReturnToHome}>
                <HomeOutlinedIcon style={{ marginRight: "5px" }} />
                Back to Homepage
              </Button>
            </div>
          </div>
        </Modal>
      </main>
    </AuthLayout>
  );
}
