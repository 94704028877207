import { useMutation, useQuery } from "react-query"
import { request } from "../api/fetch"

export const useGetAssignedCourses = (userId) => {
    const getAssignedCourses = async () => {
        
        const { data } = await request.get(`/trivia/participant-course/${userId}`)
        return data.data
    }
    const query = useQuery({
        queryFn: getAssignedCourses,
        queryKey: ['get assigned courses', userId],
        refetchInterval: 20 * 60 * 1000, // Refetch every 5 minutes
        refetchOnWindowFocus: false,
        retry:false
    })
    return query
}
export const useGetAssignedCourseModule = (userId, cohortParticipantId, journeyCohortId, courseId) => {
    const getAssignedCourseModule = async () => {
      const { data } = await request.get(`/trivia/get-assigne-course-module/${userId}`, {
        params: {
          cohortParticipantId,
          journeyCohortId,
          courseId
        }
      })
      return data
    }
    const query = useQuery({
      queryFn: getAssignedCourseModule,
      queryKey: ['get assigned course module', userId, cohortParticipantId, journeyCohortId, courseId],
      enabled: false, // Only run the query when the user clicks "Take Course"
      refetchOnWindowFocus: false,
      retry: false
    })
    return query
  }



  // export const useBeginModule = () => {
  //   const beginModule = async ({ userId, cohortParticipantId, journeyCohortId, courseId, moduleId, assignedModuleId }) => {
  //     const { data } = await request.post(`/trivia/begin-module/${userId}?cohortParticipantId=${cohortParticipantId}&journeyCohortId=${journeyCohortId}&courseId=${courseId}&moduleId=${moduleId}&assignedModuleId=${assignedModuleId}`);
  //     return data;
  //   };
  
  //   return useMutation(beginModule);
  // };

  export const useBeginModule = () => {
    return useMutation(async ({ userId, cohortParticipantId, journeyCohortId, courseId, moduleId, assignedModuleId }) => {
      const { data } = await request.get(
        `/trivia/begin-module/${userId}?cohortParticipantId=${cohortParticipantId}&journeyCohortId=${journeyCohortId}&courseId=${courseId}&moduleId=${moduleId}&assignedModuleId=${assignedModuleId}`
      );
      return data;
    });
  };