import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  Input,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../components/layout/AuthLayout";
import { Rating } from "react-simple-star-rating";
import { useSubmitTrivialFeedback } from "../../hooks/api/post";
import FeedbackSuccessModal from "./FeedbackSuccessModal";
import { toast } from "react-toastify";
function Feedback() {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const navigate = useNavigate();

  const [surveyAnswer, setSurveyAnswer] = useState({
    rating: null,
    sufficientTime: "",
    otherFeedback: "",
  });
  const handleRating = (rating) => {
    setSurveyAnswer((prev) => ({ ...prev, rating }));
  };
  const feedbackApi = useSubmitTrivialFeedback();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("ccAuth"))
  );
  const quiz_id = sessionStorage.getItem("quizID");

  const submitFeedback = () => {
    feedbackApi.mutate(
      {
        user_id: userInfo?.id,
        quiz_id,
        feedbacks: [
          {
            question: "How many star rating",
            answer: surveyAnswer.rating,
          },
          {
            question: "Sufficient time",
            answer: surveyAnswer.sufficientTime,
          },
          {
            question: "Other Feedbacks",
            answer: surveyAnswer.otherFeedback,
          },
        ],
      },
      {
        onSuccess: () => {
          setOpenSuccessModal(true);
        },
        onError: () => {
          toast.dismiss();
          toast.error("Unable to submit feedback. Kindly try again after 1min");
        },
      }
    );
  };
  return (
    <AuthLayout data-testid="authLayout">
      <FeedbackSuccessModal
        open={openSuccessModal}
        title={"Thank you for your feedback"}
        handleClose={() => navigate("../homepage")}
      />
      <Box my={1} sx={{ display: "flex", justifyContent: "right" }}>
        <Button color="error" onClick={submitFeedback}>
          <Typography fontWeight={800} color="error">
            Skip
          </Typography>
        </Button>
      </Box>
      <Typography
        variant="h4"
        fontSize={18}
        fontWeight={900}
        color="#7F7F7F"
        align="center"
      >
        We Would Love To Hear From You
      </Typography>
      <Typography variant="h4" fontSize={12} align="center" my={2} px={1}>
        We would like your feedback to help us improve your experience
      </Typography>
      <Box
        sx={{
          padding: "15px",
          minHeight: "calc(100vh - 220px)",
          backgroundColor: "#F8F8F8",
          marginTop: "40px",
          borderTopLeftRadius: "40px",
          borderTopRightRadius: "40px",
        }}
      >
        <Box>
          <Typography>
            1. How would you rate your overall experience during the one-day
            free trial on Qwizfun.ng?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Rating
              fillColorArray={[
                "#f14f45",
                "#f16c45",
                "#f18845",
                "#f1b345",
                "#f1d045",
              ]}
              onClick={handleRating}
              transition
            />
          </Box>
        </Box>
        <Box my={2.5}>
          <Typography>
            2. Did you find the trial period duration (24 hours) sufficient to
            explore and evaluate the platform's features?
          </Typography>
          <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                onChange={(e) => {
                  setSurveyAnswer((prev) => ({
                    ...prev,
                    sufficientTime: e.target.value,
                  }));
                }}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />

                <FormControlLabel
                  value="Skipped"
                  control={<Radio />}
                  label="Didn't use the trial"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Typography>
            3. Do you have any suggestions or feedback on how we can enhance the
            free trial experience for future users?{" "}
          </Typography>
          <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
            <Input
              onChange={(e) => {
                setSurveyAnswer((prev) => ({
                  ...prev,
                  otherFeedback: e.target.value,
                }));
              }}
              fullWidth
              placeholder="I would like if ______"
            />
          </Box>
        </Box>

        <Box mt={3}>
          <Button
            onClick={submitFeedback}
            fullWidth
            variant="contained"
            bgcolor="#61A641"
            sx={{ bgcolor: "#61A641" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </AuthLayout>
  );
}

export default Feedback;
