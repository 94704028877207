import { useState } from "react";

export default function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <div className="w-full max-w-3xl mx-auto p-4">
      {/* Tab Buttons */}
      <div className="flex border-b border-gray-300">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`relative flex-1 py-2 px-4 text-left text-gray-600 font-medium transition-all duration-300 ${
              activeTab === tab.id ? "text-primaryBlue font-bold border-b-4 border-[#F07404]" : ""
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Render Active Tab Content */}
      <div className="p-4">
        {tabs.map(
          (tab) =>
            activeTab === tab.id && (
              <div key={tab.id} className="text-gray-700">
                {tab.content}
              </div>
            )
        )}
      </div>
    </div>
  );
}
