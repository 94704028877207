import React, { useContext } from "react";
import "./answerCorrect.css";
// import Girl_hands_up from "../../assets/images/Thumbs Up.png";
import Girl_hands_up from "../../assets/images/correct.png";
import { useNavigate } from "react-router-dom";

const AnswerCorrect = ({
  nextQTN,
  hideModal,
  currentQuestion,
  quizQuestions,
  nextQuestion,
}) => {
  const navigate = useNavigate();

  // const handleClick = ()=>{
  //     if(currentQuestion < quizQuestions?.data?.length){
  //         hideModal()
  //         nextQTN()
  //     }

  // }

  const handleClick = () => {
    nextQuestion();
  };

  return (
    <div className="answerCorrect">
      <div className="answerCorrect-container">
        <div className="answerCorrect-hold">
          <div className="answerCorrect-wrap">
            <div className="answerCorrect-imgWrap">
              <img src={Girl_hands_up} alt="" />
            </div>
            <div className="answerCorrect-textsWrap">
              <div className="answerCorrect-title">Correct!</div>
              <h4>You are doing great.</h4>
              <button onClick={handleClick}>NEXT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerCorrect;
