import React from "react";
import { ContentPagePanel } from "../helpPage/HelpPage";
import AuthLayout from "../../components/layout/AuthLayout";

import { trivia } from "../../../Data";
import Layout from "../../components/layout/Layout";
function TAC() {
  const triviaList = [...trivia.tac].map((data) => {
    data.content = data.subtitle;

    if (!data.title) {
      data.title = "";
    }
    return data;
  });
  return (
    <Layout>
      <ContentPagePanel title="Terms and Conditions" options={triviaList} />
    </Layout>
  );
}

export default TAC;
