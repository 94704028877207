import React, { useState } from "react";
import { useFetchPlans } from "../../../hooks/api/fetch";
import { useNavigate } from "react-router-dom";
import { useSubscribe } from "../../../hooks/api/post";
import { toast } from "react-toastify";
import PaymentMethod from "../PaymentMethod/PaymentMethod";

//image & icons
import loginImage from "../../../assets/images/login-image.png";
// component
import Button from "../../../components/QuizButton/Button";
import AuthLayout from "../../../components/layout/AuthLayout";
import Socket from "../../../components/Socket/Socket";

export default function AvailablePlan() {
  const [active, setActive] = useState(null);
  const [reloader, setReloader] = useState(0);
  const [activePayment, setActivePayment] = useState(null);
  const getUserID = sessionStorage.getItem("ccAuth");
  const userID = JSON.parse(getUserID);
  const token = userID.token;
  const usersDigit = userID.userPassport;
  const [channel, setChannel] = useState("airtime");
  const navigate = useNavigate();
  const subscribe = useSubscribe();
  const [showSubscribersScreen, setShowSubscribersScreen] = useState(false);
  const [planID, setPlanID] = useState("");
  const { fetchAllPlansQuery, isLoading } = useFetchPlans(token);

//   console.log(fetchAllPlansQuery)
//  console.log(token)



  const handlePaymentType = (id) => {
    setShowSubscribersScreen(true);
    // navigate("/qwiz/subscribe")
    // sessionStorage.setItem("plan_id", id)
    setPlanID(id);
    setActive(id);
  };

  const HandleActive = (buttonid) => {
    setActivePayment(buttonid);
    setChannel(buttonid);
  };

  // handle subscription button
  async function handleSuccess() {
    try {
      const response = await subscribe.mutateAsync({
        user_id: userID.id,
        plan_id: planID,
        payment_channel: channel,
      });

      navigate("/qwiz/processing");
    } catch (error) {
      toast.dismiss()
      toast.error(error?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    
    }
  }
  

  return (
    <AuthLayout>
      {!showSubscribersScreen ?
      <main className="paymentCtn">
        <h1 className="paymentHeading" data-testid="subscriptionText">Select a payment plan</h1>
        {/* <div className="paymentImgCtn">
          <img src={loginImage} alt="loginImage" className="paymentImg" />
        </div> */}

        <div className="paymentType">
          {isLoading ? (
            <div className="subLoadingCtn">
              <div className="subloadingstate" />
            </div>
          ) : (
            fetchAllPlansQuery?.map((plan) => (
              <div className="planBtn" key={plan.id}>
                <Button
                  type="submit"
                  handleclick={() => handlePaymentType(plan.id)}
                  btntype={`${active === plan?.id ? "active" : "main"}`}
                >
                  {plan.frequency} ₦{plan.price}
                </Button>
              </div>
            ))
          )}
         
          {/* {showSubscribersScreen && (
            <div className="">
              <p className="paymentMtd">Select Payment Method</p>
              <Button
                type="submit"
                // handleclick={()=>setChannel("airtime")}
                handleclick={() => HandleActive("airtime")}
                btntype={`${activePayment === "airtime" ? "active" : "main"}`}
              >
                Airtime
              </Button>
              <Button
                type="submit"
                // btntype={`${activePayment === "card" ? "active" : "main"}`}
                btntype="disabled"
                handleclick={() => HandleActive("card")}
                disabled={true}
              >
                Pay with card (coming soon)
              </Button>
              <Button
                type="submit"
                // btntype="subscribe"
                btntype={`${subscribe.isLoading ? "disabled" : "subscribe"}`}
                handleclick={() => handleSuccess()}
                disabled={subscribe.isLoading}
              >
                {subscribe.isLoading ? (
                  <div className="subLoadingCtn">
                    Please wait
                    <div className="subloadingstate"></div>
                  </div>
                ) : (
                  " Subscribe"
                )}
              </Button>
            </div>
          )} */}
        </div>
      </main>
       :
       <PaymentMethod planID={planID}  setShowSubscribersScreen={setShowSubscribersScreen}/>

      
       }

      <Socket setReloader={setReloader} usersDigit={usersDigit} />
    </AuthLayout>
  );
}
