import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import Image from "material-ui-image";
import Modal from "../../components/modal/Modal";
import boyImage from "../../../assets/handBoy.png";
function FeedbackSuccessModal({
  open,
  handleClose,
  title,
  subtitle,
  trialStart,
  trialEnd,
}) {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Box py={2}>
        <Typography variant="h3" fontSize={20} fontWeight={900} align="center">
          {title}
        </Typography>
        <Typography
          mt={1}
          variant="h4"
          fontSize={16}
          fontWeight={500}
          align="center"
        >
          {subtitle}
        </Typography>
        <Box py={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Box width={211}>
            <Image
              src={boyImage}
              color="transparent"
              alt="boy with opened arm"
            />
          </Box>
        </Box>

        <Typography
          mt={1}
          variant="h4"
          fontSize={16}
          fontWeight={500}
          align="center"
        >
          subscribe now to continue enjoying unlimited access to all quizzes{" "}
        </Typography>
      </Box>
      <Box py={1} pb={2} sx={{ display: "flex", justifyContent: "center" }}>
        {/* <Link to="http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web"> */}
        <Link to=  {`https://ng-app.com/VasDigimobility/9ijakidstriviaquiz-Landing-en-doi-web?origin_banner=1&trfsrc=9ijakidsweb` }>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{ backgroundColor: "#F29F06" }}
          >
            Subscribe
          </Button>
        </Link>
      </Box>
    </Modal>
  );
}

export default FeedbackSuccessModal;
