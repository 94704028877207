import React from "react";
import Select from "react-select";

export default function DropDown({
  handleChange,
  options,
  placeholder,

}) {

  return (
  <Select
    onChange={handleChange}
    options={options}
    isSearchable={true}
    isClearable={true}
    placeholder={placeholder}

  />
  );
}
