import React, { useContext } from "react";
import "./answerWrong.css";
// import WrongAnswerGirl from "../../assets/images/Sad.png";
import WrongAnswerGirl from "../../assets/images/wrong.png";
import { useNavigate } from "react-router-dom";

const AnswerWrong = ({
  nextQTN,
  hideModal,
  currentQuestion,
  quizQuestions,
  nextQuestion,
}) => {
  const navigate = useNavigate();

  // const handleClick = ()=>{
  //     if(currentQuestion < quizQuestions?.data?.length){
  //         hideModal()
  //         nextQTN()
  //     }else{
  //         console.log("lobo")
  //     }
  // }

  const handleClick = () => {
    nextQuestion();
  };

  return (
    <div className="answerWrong">
      <div className="answerWrong-container">
        <div className="answerWrong-hold">
          <div className="answerWrong-wrap">
            <div className="answerWrong-imgWrap">
              <img src={WrongAnswerGirl} alt="" />
            </div>
            <div className="answerWrong-textsWrap">
              <div className="answerWrong-title">Ooops!</div>
              <h4>I'm afraid that's not the answer.</h4>
              <button onClick={handleClick}>NEXT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerWrong;
