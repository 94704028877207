import React, { useState, useEffect, memo, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSound from "use-sound";
import { toast } from "react-toastify";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "react-circular-progressbar/dist/styles.css";
import { Box, Button } from "@mui/material";
import { request, useFetchGames } from "../../hooks/api/fetch";
import useDecrypt from "../../hooks/utils/useDecrypt";
// import Feedback from "./FeedBack/Feedback";
import "./Gameplay.css";
import AnswerCorrect from "./components/answerCorrectModal/answerCorrect";
import AnswerWrong from "./components/answerWrongModal/answerWrong";
import NewHighScore from "./components/newHighScoreModal/newHighScore";
import { useLogFraud } from "../../hooks/api/post";
import Confetti from "./components/confetti/Confetti";
import beepSound from "./assets/sounds/beep.mp3";
import clapSound from "./assets/sounds/clap.mp3";
import Feedback from "../feedback/Feedback";
const GameQuiz = () => {
  const playBeep = useSound(beepSound);
  const playClap = useSound(clapSound);
  // playClap[0]();
  const navigate = useNavigate();
  const decrypt = useDecrypt();
  const logFraud = useLogFraud();
  const quizPageRef = useRef(null);
  const userID = JSON.parse(sessionStorage.getItem("ccAuth"))?.id;
  const quizTitle = sessionStorage.getItem("quizTitle");
  const [quizQuestions, setQuizQuestions] = useState();
  // JSON.parse(sessionStorage.getItem("getQuiz"))
  // const quizId = quizQuestions?.data[0]?.quiz_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const queryID = searchParams.get("id");
  const quizId = queryID || sessionStorage.getItem("quizID");
  // console.log({ quizId });
  const queryFetchGames = useFetchGames({ payload: { quiz_id: quizId } });
  useEffect(() => {
    // if (!JSON.parse(sessionStorage.getItem("getQuiz"))) {
    setQuizQuestions(queryFetchGames?.data);

    // setQuizQuestions({
    //   ...queryFetchGames?.data,
    //   data: queryFetchGames?.data?.questions,
    // });

    // }
  }, [queryFetchGames?.data]);
  // console.log({ queryFetchGames: queryFetchGames.data });
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [myAnswer, setMyAnswer] = useState("");
  const [answerID, setMyAnswerID] = useState("");
  const [openCorrectAnswerModal, setOpenCorrectAnswerModal] = useState(false);
  const [openWrongAnswerModal, setOpenWrongAnswerModal] = useState(false);
  const [openNewHghScoreModal, setNewHghScoreModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [pleaseSelectAnswer, setPleaseSelectAnswer] = useState(false);
  const [questionID, setQuestionID] = useState(false);
  const [finalAnswer, setFinalAnswer] = useState([]);
  const [count, setCount] = useState(20);
  const [key, setKey] = useState(0);
  const [progressBar, setProgressBar] = useState(0);
  const [scorePoint, setScorePoint] = useState("");
  const [circleBr, setAUto] = useState("");
  const [thereIsanswer, setThereIsanswer] = useState(false);
  const [ii, setii] = useState("");
  // const [quizID, setQuizID] = useState(
  //   quizQuestions?.data[currentQuestion]?.quiz_id
  // );
  const [quizID, setQuizID] = useState(sessionStorage.getItem("quizID"));
  const [idd, setIdd] = useState();
  const [ageGroup, setAgeGroup] = useState(
    quizQuestions?.data[currentQuestion]?.age_category
  );
  const [there, setThere] = useState(false);
  const [loading, setLoading] = useState(false);

  const [myTime, setMyTime] = useState("");
  const [peopleInfo, setPeopleInfo] = useState([]);

  const kpoo = peopleInfo.map((item) => item.first);
  //start and pause timer
  const startPause =
    openCorrectAnswerModal === true ||
    openWrongAnswerModal === true ||
    openFeedbackModal === true ||
    openNewHghScoreModal === true ||
    queryFetchGames?.isLoading;
  const progressBarPercentage = Math.floor(
    100 / quizQuestions?.data?.length - 1
  );

  //reset timer on next quiz
  useEffect(() => {
    if (currentQuestion) {
      setKey(key + 1);
    }

    setIdd(quizQuestions?.data[currentQuestion]?.id);
  }, [currentQuestion]);

  useEffect(() => {
    if (there === true) {
      setThere(false);
    }
  }, [there]);

  const [playCount, setPlayCount] = useState(1);
  useEffect(() => {
    queryFetchGames.refetch().then((e) => {
      if (queryFetchGames?.data) {
        sessionStorage.setItem(
          "getQuiz",
          JSON.stringify(queryFetchGames?.data)
        );
      }
    });
    if (playCount === 3) {
      setOpenFeedbackModal(true);
    }
    console.log({ playCount });
  }, [playCount]);

  //play again
  const playAgain = () => {
    setNewHghScoreModal(false);
    setCurrentQuestion(0);
    setKey(key + 1);
    setFinalAnswer([]);
    setMyAnswer("");
    setProgressBar(0);
    setPlayCount((play) => play + 1);
    setQuizQuestions(queryFetchGames?.data);
    // setQuizQuestions({
    //   ...queryFetchGames?.data,
    //   data: queryFetchGames?.data?.questions,
    // });
  };

  const handleChecksbox = (e, answer) => {
    setMyAnswer("temporary");
    setThere(false);
    if (e.target.checked) {
      setPeopleInfo([
        ...peopleInfo,
        {
          id: answer.id,
          first: answer.question_option,
        },
      ]);
    } else {
      // remove from list
      setPeopleInfo(peopleInfo.filter((people) => people.id !== answer.id));
    }
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too late</div>;
    }

    setMyTime(remainingTime);

    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  const submitQuestions = () => {
    setLoading(true);
    setFinalAnswer((current) => [
      ...current,
      {
        question_answer: thereIsanswer === true ? myAnswer : "",
        id: idd,
        timeSpent: myTime,
      },
    ]);
    request
      .post(`/submit-question`, {
        quiz_id: quizID,
        user_id: userID,
        // age_group: ageGroup,
        questions: finalAnswer,
      })
      .then((res) => {
        setScorePoint(res?.data);
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openModalForCheckBoxQuestionType = () => {
    const allchecked = kpoo.sort().join();
    setMyAnswer(allchecked);
    setPleaseSelectAnswer(false);
    setThereIsanswer(true);

    setQuestionID(false);
    if (
      allchecked ===
        decrypt(quizQuestions?.data[currentQuestion]?.question_answer) &&
      myAnswer !== ""
    ) {
      setOpenCorrectAnswerModal(true);
    } else if (
      allchecked !==
        decrypt(quizQuestions?.data[currentQuestion]?.question_answer) &&
      myAnswer !== ""
    ) {
      setQuestionID(false);
      setOpenWrongAnswerModal(true);
    } else {
      nextQuestion();
    }
  };

  const openModalForOtherQuestionTypes = () => {
    setPleaseSelectAnswer(false);
    setThereIsanswer(true);

    setQuestionID(false);
    if (
      myAnswer ===
        decrypt(quizQuestions?.data[currentQuestion]?.question_answer) &&
      myAnswer !== ""
    ) {
      setOpenCorrectAnswerModal(true);
    } else if (
      myAnswer !==
        decrypt(quizQuestions?.data[currentQuestion]?.question_answer) &&
      myAnswer !== ""
    ) {
      setQuestionID(false);
      setOpenWrongAnswerModal(true);
    } else {
      nextQuestion();
    }
  };

  const openModal = () => {
    if (quizQuestions?.data[currentQuestion]?.question_type === "Checkbox") {
      openModalForCheckBoxQuestionType();
    } else {
      openModalForOtherQuestionTypes();
    }
  };

  const nextQuestion = () => {
    // setMyAnswer("")
    setThere(true);
    setPeopleInfo([]);
    setQuestionID(false);
    setOpenCorrectAnswerModal(false);
    setOpenWrongAnswerModal(false);
    if (currentQuestion < quizQuestions?.data?.length - 1) {
      setFinalAnswer((current) => [
        ...current,
        {
          question_answer: thereIsanswer === true ? myAnswer : "",
          id: idd,
          timeSpent: myTime,
        },
      ]);

      setProgressBar(progressBar + progressBarPercentage);
      setCurrentQuestion(currentQuestion + 1);
      setOpenWrongAnswerModal(false);
      setOpenCorrectAnswerModal(false);
    } else {
      setNewHghScoreModal(true);
      submitQuestions();
    }
    setMyAnswer("");
    setThereIsanswer("");
  };

  const selectedAnswer = (answer, index) => {
    setMyAnswer(answer.question_option);
    setMyAnswerID(answer.id);
    setQuestionID(index);
    // console.log(index)
  };
  function hideGame() {
    // window.location.href = "/play-games";
    navigate("/qwiz/homepage");
  }
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    document.addEventListener("visibilitychange", disableHiddenPage);

    window.addEventListener("resize", disableMinimizePage);
    disableMinimizePage();
    return () => {
      document.removeEventListener("visibilitychange", disableHiddenPage);
      window.removeEventListener("resize", disableMinimizePage);
    };
  }, []);

  const disableHiddenPage = useCallback(() => {
    if (document.hidden) {
      disableCopy("Left Quiz Page");
    }
  }, []);
  const disableMinimizePage = useCallback(() => {
    if (window.outerWidth !== window.screen.width) {
      toast.dismiss();
      logFraud.mutate(
        {
          user_id: userID,
          activities_description: "Screen not in full screen mode",
        },
        {
          onSettled: () => {
            toast.warn(
              "Please put your device in full screen, this quiz will close now"
            );
            navigate("/qwiz/homepage");
          },
        }
      );
    }
  }, []);
  const disableCopy = useCallback((reason) => {
    // e.preventDefault();

    toast.dismiss();
    logFraud.mutate(
      {
        user_id: userID,
        activities_description: reason,
      },
      {
        onSettled: () => {
          toast.warn("Irregular activity detected, this quiz will close now");
          navigate("/qwiz/homepage");
        },
      }
    );
  }, []);

  // useEffect(() => {
  //   console.log({ count });
  // }, [count]);

  useEffect(() => {
    window.chatwootSettings = {
      hideMessageBubble: true,
      position: "left", // This can be left or right
      locale: "en", // Language to be set
      useBrowserLanguage: false, // Set widget language from user's browser
      baseDomain: ".9ijakids.com",
      baseUrl: "https://teamhubpm.9ijakids.com",
      type: "standard", // [standard, expanded_bubble]
      darkMode: "auto", // [light, auto]
    };

    window.addEventListener("chatwoot", function () {
      // Use window.$chatwoot here
      window.$chatwoot.toggle("close");
      window.$chatwoot.toggleBubbleVisibility("hide");
      // ...

      // console.log({ APPchatwootSDK: window.$chatwoot });
    });
    // window.$chatwoot.toggle("close");
    window?.$chatwoot?.toggleBubbleVisibility("hide");
    // console.log({ APPchatwootSDK: window.$chatwoot });
  }, []);
  return (
    <>
      <Box
        ref={quizPageRef}
        sx={{
          width: "100%",
          // height: "40px",
          display: "flex",
          backgroundColor: "white",
          justifyContent: "right",
        }}
      >
        {/* {window.innerWidth}/ {window.outerWidth}/ {window.screen.width} */}
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            // alert("yee");
            hideGame();
            // navigate("/play-games");
          }}
          sx={{ alignSelf: "flex-end", margin: "10px" }}
        >
          X
        </Button>
      </Box>
      {/* <GameTopBar hideGame={hideGame} setShowContact={()=>null} /> */}
      <div className="QQM">
        <div className="QQM-container">
          <div className="QQM-questTitleBar">
            <div className="QQM-topiWrap">
              <h2 className="QQM-christmassText">{quizTitle}</h2>
              <span
                style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}
              >
                {quizQuestions && quizQuestions?.data[currentQuestion]?.topic}
              </span>
            </div>

            <div className="QQM-qtnCounter">
              {currentQuestion + 1}/{quizQuestions?.data.length}
            </div>
          </div>
          <div className="QQM-counter">
            <div className="QQM-timerBar">
              <div
                className="QQM-inner"
                style={{ width: `${progressBar}%` }}
              ></div>
            </div>

            <div className="QQM-progressbarWrap">
              <span className="QQM-timeLeft">Time left: </span>
              <div>
                <CountdownCircleTimer
                  counting={false}
                  key={key}
                  isPlaying={startPause ? false : true}
                  duration={count}
                  size={70}
                  strokeWidth={6}
                  colors={["#004777", "#FF0000", "#FF0000", "#FF0000"]}
                  colorsTime={[7, 5, 2, 0]}
                  onUpdate={(time) => {
                    if (time < 6) {
                      playBeep[0]();
                    } else {
                      playBeep[1].stop();
                    }
                  }}
                  onComplete={() => {
                    playBeep[1].stop();
                    nextQuestion();
                    return {
                      shouldRepeat:
                        currentQuestion < quizQuestions?.data?.length - 1 &&
                        true,
                      delay: 1.5,
                    }; // repeat animation in 1.5 seconds
                  }}
                >
                  {renderTime}
                  {/* {({ remainingTime }) => remainingTime} */}
                </CountdownCircleTimer>
              </div>
            </div>
          </div>

          <div className="QQM-QuestionAndAnswer">
            <div
              className="QQM-questionCard"
              onCopy={(e) => disableCopy("Copied Question")}
            >
              {quizQuestions && quizQuestions?.data[currentQuestion]?.question}
            </div>

            <div className="QQM-answerCard">
              {quizQuestions?.data[currentQuestion]?.question_type ===
              "Checkbox" ? (
                <>
                  {quizQuestions &&
                    quizQuestions?.data[currentQuestion]?.questionOptions.map(
                      (answer, index) => (
                        <div key={index}>
                          <div className="QQM-checkboxWrap">
                            <input
                              onChange={(e) => handleChecksbox(e, answer)}
                              checked={there === true ? false : null}
                              value={peopleInfo}
                              style={{ margin: "20px" }}
                              type="checkbox"
                              className="QQM-checkbox"
                            />
                            <div className="QQM-answers">
                              {answer.question_option}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </>
              ) : (
                <>
                  {quizQuestions &&
                    quizQuestions?.data[currentQuestion]?.questionOptions.map(
                      (answer, index) => (
                        <div key={index}>
                          <div
                            className={
                              questionID === index
                                ? "QQM-chooseAnswerActive"
                                : "QQM-chooseAnswer"
                            }
                            onClick={() => selectedAnswer(answer, index)}
                          >
                            <div className="QQM-qtn">
                              <span>{answer.question_option}</span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </>
              )}

              {pleaseSelectAnswer && (
                <div
                  style={{ fontSize: "15px", fontWeight: "bold", color: "red" }}
                >
                  Please select an answer
                </div>
              )}
              <div className="QQM-buttnWrap">
                <button onClick={openModal}>NEXT</button>
              </div>
            </div>
          </div>
        </div>

        {openCorrectAnswerModal && (
          <AnswerCorrect nextQuestion={() => nextQuestion()} />
        )}

        {openWrongAnswerModal && (
          <AnswerWrong nextQuestion={() => nextQuestion()} />
        )}

        {openFeedbackModal ? (
          // <Feedback open={openFeedbackModal} setOpen={setOpenFeedbackModal} />
          // <Feedback />
          <NavigateToFeedback />
        ) : (
          openNewHghScoreModal && (
            <>
              <NewHighScore
                showHomepage={true}
                submitQuestions={() => submitQuestions()}
                scorePoint={scorePoint}
                playAgain={() => playAgain()}
                loading={loading}
                stopSound={playClap[1].stop}
              />
              {/* Confetti image display once games end */}
              <Confetti playSound={playClap[0]} />
            </>
          )
        )}
      </div>
    </>
  );
};

export default GameQuiz;

function NavigateToFeedback() {
  const navigate = useNavigate();
  navigate(`../quizfeedback`);
  return <></>;
}
