import { useMutation, useQuery } from "react-query"
import { request } from "../api/fetch"

export const useFetchWalletBalance = (userID) => {
    const fetchBalance = async () => {
        
        const { data } = await request.get(`/trivia/wallet/check/${userID}`)
        return data
    }
    const query = useQuery({
        queryFn: fetchBalance,
        queryKey: ['fetch wallet balance', userID],
        refetchOnWindowFocus: false,
        retry:false
    })
    return query
}
export const useCreateWallet = () => {
    const createWallet = async({user_id, pin}) => {
        const { data } = await request.post(`/trivia/wallet/create`, { user_id, pin})
        return data
    }
    const mutate = useMutation({
        mutationFn: createWallet,
        mutationKey:["create wallet"]
    })
return mutate
}
export const useFundWallet = () => {
    const fundWallet = async({user_id, amount, email}) => {
        const { data } = await request.post(`/trivia/wallet/fund`, { user_id, amount , redirectionLink:"http://ng.qwizfun.com/qwiz/wallet", email})
        return data
    }
    const mutate = useMutation({
        mutationFn: fundWallet,
        mutationKey:["fund wallet"]
    })
return mutate
}