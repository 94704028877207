import React, { useContext, useEffect, useState } from "react";
import PagePanel from "../../components/pagePanel/PagePanel2";
import { summer, trivia } from "../../Data";
import { VersionContext } from "../../context/VersionContext";

function HowToPlay() {
  const { version } = useContext(VersionContext);
  const [options, setOptions] = useState(trivia.rules);

  return <PagePanel options={options} title="Competition Rules" />;
}

export default HowToPlay;
