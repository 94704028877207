import React from "react";
import { ContentPagePanel } from "../helpPage/HelpPage";
import AuthLayout from "../../components/layout/AuthLayout";

import { trivia } from "../../../Data";
function rulesPage() {
  const triviaList = trivia.rules.map((data) => {
    data.content = data.text;
    if (!data.title) {
      data.title = "";
    }
    return data;
  });
  return (
    <AuthLayout>
      <ContentPagePanel title="Rules" options={triviaList} />
    </AuthLayout>
  );
}

export default rulesPage;
