import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect, useState, lazy, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { VersionContext } from "./context/VersionContext";
// STYLES
import "./App.css";

// COMPONENTS
// import Login from "./pages/login/Login";
// import Home from "./pages/home/home";

import NoSubscription from "./pages/NoSubscription/NoSubscription";
// import SubscriptionExpired from "./pages/SubscriptionExpired/SubscriptionExpired";
// import Contact from "./pages/contact/Contact";
// import SubscriptionProcessing from "./pages/subscription/SubscriptionProcessing";
// import ManageSubscription from "./pages/subscription/ManageSubscription";
import BottomNav from "./components/BottomNav/BottomNav";
import CreateProfileModal from "./pages/createProfile/CreateProfileModal";
import ChatwootWidget from "./components/ChatwootWidget/ChatwootWidget";
import SummerChatWoot from "./components/ChatwootWidget/SummerChatWoot";
import GamePlay from "./pages/gamesPlay/GamePlay";
import ExpiredNotificationCard from "./components/expiredNotificationCard/expiredNotificationCard";
import Badges from "./pages/badges/badges";
import Loader from "./loaders/loaders";
import Leadercard from "./components/presentation/Leadercard";
import { summer, trivia } from "./Data";
import Redirect from "./pages/redirect/Redirect";
import Stats from "./admin/pages/stats/Stats";
import Referrals from "./admin/pages/referrals/Referrals";
import Mobi2 from "./Mobi2.0/Mobi2";

const Login = lazy(() => import("./pages/login/Login"));
const Home = lazy(() => import("./pages/home/home"));
const LandingPage = lazy(() => import("./pages/landingPage/LandingPage"));
const MarketingLandingPage = lazy(() =>
  import("./pages/landingPage/MarketingLandingPage")
);
const TosseLandingPage = lazy(() =>
  import("./pages/landingPage/TosseLandingPage")
);
const Subscribers = lazy(() =>
  import("./admin/pages/subscribers2/Subscribers2")
);
const AllGames = lazy(() => import("./pages/games/AllGames"));
const Faq = lazy(() => import("./pages/faq/faq"));
const Faq2 = lazy(() => import("./pages/faq/Faq2"));
const TAC = lazy(() => import("./pages/tac/TAC"));
const HowToPlay = lazy(() => import("./pages/howToPlay/HowToPlay"));
const Rules = lazy(() => import("./pages/howToPlay/Rules"));
const SubscriptionPlan = lazy(() =>
  import("./pages/subscription/SubscriptionPlan")
);
const Profile = lazy(() => import("./pages/profile/profile"));
const CreateProfile = lazy(() => import("./pages/createProfile/CreateProfile"));
const Leaderboard = lazy(() => import("./pages/leaderboard/leaderboard"));
const Games = lazy(() => import("./pages/games/Games"));
const Rewards = lazy(() => import("./pages/Rewards/Rewards"));
const Friends = lazy(() => import("./pages/friends/friends"));

const AdminLogin = lazy(() => import("./admin/pages/login/login"));
const Summary = lazy(() => import("./admin/pages/summary/Summary"));
const AdminGames = lazy(() => import("./admin/pages/games/games"));
const Plans = lazy(() => import("./admin/pages/plans/plans"));
const AddEditGame = lazy(() =>
  import("./admin/pages/games/addEditGame/addEditGame")
);
const SubscriberProfile = lazy(() =>
  import("./admin/pages/subscribers/subscriberProfile/subscriberProfile")
);
const GameProfile = lazy(() =>
  import("./admin/pages/games/gameProfile/gameProfile")
);
const PlanProfile = lazy(() =>
  import("./admin/pages/plans/planProfile/planProfile")
);

function App() {
  const { pathname } = useLocation();
  const [version, setVersion] = useState(
    pathname.includes("mtn")
      ? "mtn"
      : pathname.includes("tosse")
      ? "tosse"
      : "glo"
  );
  const [adminVersion, setAdminVersion] = useState("");
  const [quiz, setQuiz] = useState({
    id: "",
    title: "",
  });
  const [quizAPIData, setQuizAPIData] = useState();

  useEffect(() => {
    version !== "glo" &&
      document.body.style.setProperty("--green-color", "#F29F06");
  }, []);
  const [isAuth, setIsAuth] = useState("");
  const [showCreateProfileModal, setShowCreateProfileModal] = useState(false);

  const authe = JSON.parse(sessionStorage.getItem("ccAuth"));

  const routes = "/login";

  // console.log(pathname);

  // CHECK USERS AUTH STATUS AS THEY MOVE THROUGH THE APPLICATION
  useEffect(() => {
    const auth = sessionStorage.getItem("ccAuth");
    setIsAuth(auth);
  }, [pathname]);

  // GUARD ROUTES FROM USERS THAT ARE NOT LOGGED IN
  const ProtectedRoute = ({ Component }) => {
    const userInfo = JSON.parse(sessionStorage.getItem("ccAuth"));

    return userInfo ? <Component /> : <Navigate to={`/${version}`} />;
  };

  const handleProfileModal = () => {
    setShowCreateProfileModal(showCreateProfileModal ? false : true);
  };

  //MAKE ARRAY
  const array = pathname.split("/");

  //GET THE LAST SEGMENT OF THE URL
  const lastsegment = array[array.length - 1];

  // UNLOCK SCREEN ORIENTATION FROM LANDSCAPE IF NOT ON GAME PLAY PAGE
  useEffect(() => {
    const ori = sessionStorage.getItem("orientation");

    if (pathname !== `/games/${lastsegment}` && ori == "locked") {
      sessionStorage.setItem("ori", false);

      const unlock = () => {
        // (B1) UNLOCK FIRST
        window.screen.orientation.unlock();

        // (B2) THEN EXIT FULL SCREEN
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      };
      unlock();
    }
  }, [pathname]);

  return (
    <VersionContext.Provider
      value={{
        version,
        setVersion,
        adminVersion,
        setAdminVersion,
        quiz,
        setQuiz,
        quizAPIData,
        setQuizAPIData,
      }}
    >
      <div className={isAuth ? "App nav-padding" : "App"}>
        {/* {isAuth && <ExpiredNotificationCard />} */}

        {/* <-------ROUTES START-------> */}
        <Suspense fallback={<Loader />}>
          <Routes>
            {/* USER DASHBOARD ROUTES */}
            <Route exact path="/" element={<Navigate to="/qwiz" />} />
            <Route exact path="/qwiz/*" element={<Mobi2 />} />
            <Route exact path="/mtn" element={<LandingPage />} />
            <Route
              exact
              path="/mtn/tac"
              element={<TAC options={summer.tac} />}
            />

            <Route exact path="/:id/demo" element={<MarketingLandingPage />} />
            <Route exact path="/tosse" element={<TosseLandingPage />} />
            {/* <Route
              exact
              path="/tosse"
              element={<Redirect link="https://conference.9ijakids.com" />}
            /> */}
            <Route
              exact
              path="/tosse/subscription-plans"
              element={
                <Redirect link="https://conference.9ijakids.com/games" />
              }
              // element={<Navigate to="/tosse/games" />}
            />
            <Route
              exact
              path="/tosse/faq"
              element={
                <Redirect link="https://conference.9ijakids.com/games" />
              }
              // element={<Navigate to="/tosse/games" />}
            />
            <Route
              exact
              path="/tosse/login"
              // element={<Navigate to="/tosse/games" />}
              element={
                <Redirect link="https://conference.9ijakids.com/games" />
              }
            />

            <Route
              exact
              path="/test"
              element={<Leadercard title="LeaderBoard" />}
            />
            <Route path="/:id" element={<Home />} />
            <Route path="/:id/faq" element={<Faq />} />
            <Route path="/mtn/faq" element={<Faq2 options={summer.faq} />} />
            <Route path="/tac" element={<TAC options={trivia.tac} />} />
            {/* <Route path="/:id/help" element={<HowToPlay />} /> */}
            {/* <Route path="/:id/rules" element={<Rules />} /> */}
            <Route
              path="/:id/subscription-plans"
              element={<SubscriptionPlan />}
            />
            <Route path="/:id/no-subscription" element={<NoSubscription />} />

            <Route
              path="/login"
              element={!isAuth ? <Login /> : <Navigate to="/games" />}
            />
            <Route
              path="/:id/login"
              element={!isAuth ? <Login /> : <Navigate to="/games" />}
            />
            <Route
              path="/games"
              element={<ProtectedRoute Component={Games} />}
            />
            <Route
              path="/:id/games"
              element={<ProtectedRoute Component={Games} />}
            />
            <Route
              path="/:id/rewards"
              element={<ProtectedRoute Component={Rewards} />}
            />
            <Route
              path="/:id/badges"
              element={<ProtectedRoute Component={Badges} />}
            />
            {/* <Route
              path="/:id/leaderboard"
              element={<ProtectedRoute Component={Leaderboard} />}
            /> */}
            {/* <Route
              path="/:id/profile"
              element={<ProtectedRoute Component={Profile} />}
            /> */}
            <Route
              path="/:id/friends"
              element={<ProtectedRoute Component={Friends} />}
            />
            <Route
              path="/:id/create-profile"
              element={<ProtectedRoute Component={CreateProfile} />}
            />
            <Route
              path="/:id/all-games"
              element={<ProtectedRoute Component={AllGames} />}
            />
            <Route
              path="/:id/games/:gameId"
              element={<ProtectedRoute Component={GamePlay} />}
            />

            {/* ADMIN DASHBOARD ROUTES */}
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/summary" element={<Summary />} />
            <Route path="/admin/subscribers" element={<Subscribers />} />
            <Route path="/admin/referrals" element={<Referrals />} />
            <Route
              path="/admin/subscribers/:userPassport"
              element={<SubscriberProfile />}
            />
            <Route path="/admin/games" element={<AdminGames />} />
            <Route path={"/admin/games/:gameId"} element={<GameProfile />} />
            <Route path="/admin/games/add-game" element={<AddEditGame />} />
            <Route path="/admin/games/edit-game" element={<AddEditGame />} />
            <Route path="/admin/rewards" element={<Subscribers />} />
            <Route path="/admin/plans" element={<Plans />} />
            <Route path="/admin/plans/profile" element={<PlanProfile />} />
            <Route path="/admin/stats" element={<Stats />} />
          </Routes>
        </Suspense>

        {/* <-------ROUTES END-------> */}

        {/* {pathname === `/${version}/login` && (
          <ChatwootWidget token="48VGKBfzwenFrGekq9TytyKw" baseUrl="" />
        )} */}
        {version === `mtn` && !JSON.parse(sessionStorage.getItem("ccAuth")) ? (
          <SummerChatWoot />
        ) : null}

        {/* {isAuth && <BottomNav />} */}
        {showCreateProfileModal && (
          <CreateProfileModal handleModal={handleProfileModal} />
        )}
        <ToastContainer />
      </div>
    </VersionContext.Provider>
  );
}

export default App;
