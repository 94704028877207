import { useMutation, useQuery } from "react-query";
import { request } from "./fetch";

async function poster(url, payload) {
  const { data } = await request.post(url, payload);
  return data;
}

export const usePostFeedback = () => {
  const postFeedbackApi = (payload) => poster("/quiz/rating", payload);
  const mutatePostFeedback = useMutation(postFeedbackApi);
  return mutatePostFeedback;
};

export const useRegistration = () => {
  const registerAPI = (payload) => poster("/trivia/register", payload);
  const mutateRegisterAPI = useMutation(registerAPI);
  return mutateRegisterAPI;
};
export const useLogin = () => {
  const loginAPI = (payload) => poster("/trivia/login", payload);
  const mutateLoginAPI = useMutation(loginAPI);
  return mutateLoginAPI;
};
export const useSubscribe = () => {
  const subscribe = (payload) => poster("/trivia/subscribe", payload);
  const mutateSubscribe = useMutation(subscribe);
  return mutateSubscribe;
};
export const useUpdateProfile = () => {
  // payload:{userId:string, data:{name:string, display_name:string, avatar:"avatar url", first_login:bool}}
  async function updateProfileApi(payload) {
    const { data } = await request.patch(
      `/trivia/update-profile/${payload.userId}`,
      payload.data
    );
    return data;
  }
  const mutateProfile = useMutation(updateProfileApi);
  return mutateProfile;
};

export const useSetPin = () => {
  async function setPin(payload) {
    const { data } = await request.post(`/trivia/pin/create`, payload);
    return data;
  }
  const createPin = useMutation(setPin);
  return createPin;
};

// CHANGE PIN
export const useChangePin = () => {
  async function changePin(payload) {
    const { data } = await request.patch(`/trivia/pin/change`, payload);
    return data;
  }
  const updatePin = useMutation(changePin);
  return updatePin;
};

// ADD ACCOUNT
export const useAddAccount = () => {
  async function addAccount(payload) {
    const { data } = await request.post(`/trivia/bank/add-account`, payload);
    return data;
  }
  const AddAcountDetails = useMutation(addAccount);
  return AddAcountDetails;
};

// EDIT  ACCOUNT
export const useEditAccount = (accountID) => {
  async function EditAccount(payload) {
    const { data } = await request.patch(
      `/trivia/bank/edit-account/${accountID}`,
      payload
    );
    return data;
  }
  const EditUserAccountDetails = useMutation(EditAccount);
  return EditUserAccountDetails;
};

// CHECK IF USER ALREADY CREATED A PIN
export const useConfirmPinStatus = () => {
  async function ConfirmPinStatus(payload) {
    const { data } = await request.post(`/trivia/check-pin`, payload);
    return data;
  }
  const confirmUsersPinStatus = useMutation(ConfirmPinStatus);
  return confirmUsersPinStatus;
};

export const useLogFraud = () => {
  // const payload:{user_id:userID, activities_description:"string"}
  const fraud = (payload) => poster("/trivia/log-fruad", payload);
  const mutatefraud = useMutation(fraud);
  return mutatefraud;
};
export const useAcknowledgeReward = () => {
  // const payload:{user_id:userID }
  async function getter(url, payload) {
    const { data } = await request.get(url, payload);
    return data;
  }
  const acknowledge = (payload) =>
    getter(`/trivia/acknownledge-winning/${payload.user_id}`, payload);
  const mutateAcknowledge = useMutation(acknowledge);
  return mutateAcknowledge;
};

// RECORD MARKETING TRAFFIC 
  export const useCheckMaketingTrafficRecord = () => {
    async function trafficRecord(payload) {
      const { data } = await request.post(`/trivia/record-traffic`, payload);
      return data;
    }
    const checkMarketingTraffic= useMutation(trafficRecord);
    return checkMarketingTraffic;
  };
  export const useCheckMaketingTrafficName = ({name}) => {
    async function trafficRecord() {
      const { data } = await request.get(`https://gameapi.9ijakids.com/trivia/marketing-company/${name}`);
      return data;
    }
    const checkMarketingTraffic= useQuery(`check-marketing-traffic-${name}`,trafficRecord);
    return checkMarketingTraffic;
  };


// Trial feedback
  
export const useSubmitTrivialFeedback = () => {
  const postFeedback = async (payload) => {
    const { data } = await request.post(`/trivia/submit-feedback`, payload)
    return data
  }
  const mutateFeedback = useMutation(postFeedback)
  return mutateFeedback
}
export const useAcknowledger = (url) => {
  const acknowledge = async (payload) => {
    const { data } = await request.post(url, payload)
    return data
  }
  const mutateAcknowledge = useMutation(acknowledge)
  return mutateAcknowledge
}
export const useAcknowledgeTrial = () => {
  const acknowledge = async (payload) => {
    const { data } = await request.patch(`trivia/update-congratulation-status/${payload?.phoneNumber}`)
    return data
  }
  const mutateAcknowledge = useMutation(acknowledge)
  return mutateAcknowledge
}