import React, { useEffect } from "react";
import { useMutation } from "react-query"; // Import useMutation
import AuthLayout from "../../components/layout/AuthLayout";
import { useCourseModule } from "./context/CourseModuleContext"; // Ensure correct path
import { request } from "../../hooks/api/fetch"; // Ensure correct path
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { Paper, Button, Typography, Box, Stack, Skeleton } from "@mui/material";
import ProgressBar from "./components/ProgressBar";
import CourseImage from "../../assets/images/Frame 1000003420.png";
import Modules from "./components/Modules";
import Assessment from "./components/Assessment";
import { ClipLoader } from "react-spinners";
const CourseModules = () => {
  const navigate = useNavigate();
  const ccAuth = JSON.parse(sessionStorage.getItem("ccAuth") || "{}");
  const course = JSON.parse(sessionStorage.getItem("course"));
  const cohortParticipantId = sessionStorage.getItem("cohortParticipantId");
  const journeyCohortId = sessionStorage.getItem("journeyCohortId");
  const userId = ccAuth?.id;
  const courseId = course?.courseId;

  const { courseModules, setCourseModules } = useCourseModule();

  console.log(courseModules, "module course");
  const completedModules =
    courseModules?.modules?.reduce(
      (acc, module) => acc + (module?.moduleCompletion ? 1 : 0),
      0
    ) || 0;

  const completionPercentage = (modules) => {
    const totalModules = modules?.length;
    const completedModules = modules?.reduce(
      (acc, module) => acc + (module?.moduleCompletion ? 1 : 0),
      0
    );
    return (completedModules / totalModules) * 100;
  };

  const assessmentCompletionPercentage = (assessment) => {
    const totalassessment = assessment?.length;
    const completedassessment = assessment?.reduce(
      (acc, ass) => acc + (ass?.moduleCompletion ? 1 : 0),
      0
    );
    return (completedassessment / totalassessment) * 100;
  };

  const percentage = completionPercentage(courseModules?.modules);

  // Fetch Course Modules
  const getAssignedCourseModule = async (
    userId,
    cohortParticipantId,
    journeyCohortId,
    courseId
  ) => {
    const { data } = await request.get(
      `/trivia/get-assigne-course-module/${userId}?cohortParticipantId=${cohortParticipantId}&journeyCohortId=${journeyCohortId}&courseId=${courseId}`
    );
    return data;
  };

  const {
    mutate,
    isLoading: courseModuleLoading,
    error: courseModuleError,
  } = useMutation(
    ({ userId, cohortParticipantId, journeyCohortId, courseId }) =>
      getAssignedCourseModule(
        userId,
        cohortParticipantId,
        journeyCohortId,
        courseId
      ),
    {
      onSuccess: (data) => {
        setCourseModules(data.data);
        console.log(data, "journey");
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  // Trigger mutation on component mount
  useEffect(() => {
    if (userId && cohortParticipantId && journeyCohortId && courseId) {
      mutate({ userId, cohortParticipantId, journeyCohortId, courseId });
    }
  }, [userId, cohortParticipantId, journeyCohortId, courseId, mutate]);

  return (
    <AuthLayout data-testid="authLayout">
      <div className="py-6">
        <div>
          <Link
            onClick={() => navigate(-1)}
            className="flex px-5 py-2 bg-white mt-3 shadow border border-gray-100 items-center justify-start gap-1 max-w-fit"
          >
            <IoIosArrowRoundBack className="w-4" />
            <Typography
              variant="h3"
              fontSize={20}
              fontWeight={900}
              align="center"
            >
              Back
            </Typography>
          </Link>
        </div>
      </div>
      {courseModuleLoading ? (
        <div className="flex justify-center items-center h-screen">
            <ClipLoader className="bg-[#F07404]" />
        </div>
      ) : (
        <section className="bg-white p-7 border-t border-gray-100 shadow mt-4">
          <div className="w-full">
            <div className="flex w-full sm:flex-row flex-col justify-between items-center gap-3">
              <Typography variant="h3" fontSize={24} fontWeight={700}>
                {courseModules?.courseName}
              </Typography>
            </div>
            <section className="grid grid-cols-2 gap-4 mt-4 w-full">
              <Typography
                fontSize={12}
                fontWeight={500}
                className="flex text-gray-700 border border-gray-100 font-medium py-3 justify-center rounded-md bg-gray-100 items-center"
                as="span"
              >
                modules: &nbsp;
                {courseModules?.modules?.length}
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={500}
                className="flex text-gray-700 border border-gray-100 font-medium py-3 justify-center rounded-md bg-gray-100 items-center"
                as="span"
              >
                completed: &nbsp;
                {completedModules}
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={500}
                className="flex text-gray-700 border border-gray-100 font-medium py-3 justify-center rounded-md bg-gray-100 items-center"
                as="span"
              >
                assessments: &nbsp;
                {course?.numberOfAssessments}
              </Typography>
              <Typography
                fontSize={12}
                fontWeight={500}
                className="flex text-gray-700 border border-gray-100 font-medium py-3 justify-center rounded-md bg-gray-100 items-center"
                as="span"
              >
                completed: &nbsp;
                {course?.numberOfCompletedAssessments}
              </Typography>
            </section>
          </div>
          <section className="md:flex flex justify-between w-full mt-4 items-center">
            <div className="flex-1 flex items-center justify-start gap-2">
              {/* <Progress className="h-[5px]" value={percentage} /> */}
              <ProgressBar progress={percentage} />
              <Typography fontSize={12} fontWeight={500}>
                {Math.round(percentage)}%
              </Typography>
            </div>
          </section>
          <section className="w-full mt-4">
            <img
              src={CourseImage}
              alt="course"
              className="h-[250px] w-full"
              //width={100}
              // height={200}
            />
          </section>
          <section className="mt-5">
            <div className="">
              {courseModules?.modules?.map((module, index) => {
                const previousCourseCompleted =
                  index === 0 ||
                  courseModules?.modules[index - 1].moduleCompletion === true;
                const isLocked = !previousCourseCompleted;
                return (
                  <Modules
                    index={index + 1}
                    module={module}
                    key={module?.moduleId}
                    isLocked={isLocked}
                  />
                );
              })}
              {courseModules?.assessments?.map((module, index) => {
                const previousCourseCompleted =
                  index === 0 ||
                  courseModules.modules[index - 1].moduleCompletion === true;
                const isLocked = !previousCourseCompleted;
                return (
                  <Assessment
                    module={module}
                    index={index + 1}
                    key={module?.moduleId}
                    isLocked={percentage !== 100}
                  />
                );
              })}
            </div>
          </section>
        </section>
      )}
      {courseModuleError && (
        <p className="text-red-500">Error loading modules.</p>
      )}
    </AuthLayout>
  );
};

export default CourseModules;
