import { toast } from "react-toastify"
import { useBuyPlays } from "../../hooks/buySpin/useBuySpin"
import { useFetchTrialStatus } from "../../hooks/api/fetch"
import { queryClient } from "../../.."
import { useNavigate } from "react-router-dom"


const useBuySpinLogic = () => {
  const navigate= useNavigate()
    const ccAuth = JSON.parse(sessionStorage.getItem("ccAuth") || "")
    const buyPlay = useBuyPlays()
    const subscriptionStatus = useFetchTrialStatus({
        phone_number: ccAuth?.userPassport,
      });
    
    const handleBuyPlay = (product_type, count) => {
        if (!window.confirm("Are you sure you want to purchase this play?")) {
            return 
        }
       
        toast.loading('Please wait.... Currently purchasing your plays')
        buyPlay.mutate({
            product_type, count, user_id:ccAuth?.id
        }, {
            onSuccess: () => {
                toast.dismiss()
                toast.success("Plays bought successfully")
                queryClient.invalidateQueries('fetch wallet balance')
                
            },
            onError: (err) => {
                toast.dismiss()
                toast.error(err?.response?.data?.message)
                // if (err?.response?.data?.message==="") {
                //     navigate(`/qwiz/wallet`)
                // }
                navigate(`/qwiz/wallet`)
            }
        })
    }
    return {handleBuyPlay, subscriptionStatus}
}
export default useBuySpinLogic