import { useState } from "react";
import Button from "../../components/QuizButton/Button";
import AuthCode from "react-auth-code-input";

export default function SetPin({
  setPinText,
  confirmPinText,
  createPinText,
  next,
  handleCreatePin,
  setCreateUserPin,
  createPin
}) {
  const [nextPage, setNextPage] = useState(false);

  const handleSetPin = (word, state) => {
    setCreateUserPin((pin) => ({ ...pin, [state]: word }));
  };



  return (
    <>
      {!nextPage ? (
        <div className="paymentAmt">
          <h3 className="pinSettingsheading">{setPinText}</h3>
          <div>
            <AuthCode
              allowedCharacters="numeric"
              length="4"
              inputClassName="setPinForm"
              placeholder="*"
              onChange={(word) => handleSetPin(word, "pin")}
            />
          </div>

          <Button
            type="submit"
            btntype="active"
            handleclick={() => setNextPage(true)}
          >
            {next}
          </Button>
        </div>
      ) : (
        <div className="paymentAmt">
          <h3 className="pinSettingsheading">{confirmPinText}</h3>
          <AuthCode
            allowedCharacters="numeric"
            length="4"
            inputClassName="setPinForm"
            placeholder="*"
            onChange={(word) => handleSetPin(word, "confirmPin")}
          />

          <Button
            type="submit"
            handleclick={() => handleCreatePin()}
            btntype={`${
              createPin.isLoading ? "disabled" : "active"
              
            }`}
            disabled={createPin.isLoading}
          >
            {createPin.isLoading ? (
                    <div className="subLoadingCtn">
                      Please wait
                      <div className="subloadingstate"></div>
                    </div>
                  ) : (
                    createPinText
                  )}
          </Button>
        </div>
      )}
    </>
  );
}
