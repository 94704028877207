import React, { useContext, useEffect, useState } from "react";
import PagePanel from "../../components/pagePanel/PagePanel";
import { summer, trivia } from "../../Data";
import { VersionContext } from "../../context/VersionContext";

function HowToPlay() {
  const { version } = useContext(VersionContext);
  const [options, setOptions] = useState(trivia.tac);
  // useEffect(() => {
  //   if (version === "mtn") {
  //     setOptions(summer.rules);
  //   } else {
  //     setOptions(summer.rules);
  //   }
  // }, []);

  return <PagePanel options={options} title="Terms and Conditions" />;
}

export default HowToPlay;
