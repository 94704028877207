import { Avatar, Box, Grid, Stack, Divider, Typography } from "@mui/material";

// import Stack from "@mui/joy/Stack";
// import Divider from "@mui/joy/Divider";
import React from "react";

function VerticalDivider({ sn, text, title, subtitle }) {
  return (
    <Stack direction="row" sx={{ alignItems: "center", gap: "12px" }}>
      <Divider orientation="vertical" flexItem>
        <Avatar sx={{ padding: "3px", backgroundColor: "#61CBEF" }}>
          {sn}
        </Avatar>
      </Divider>
      <Box sx={{ margin: "10px 0", display: "flex" }}>
        {/* <Box
        // sx={{ minWidth: "180px", border: "1px solid red" }}
        >
          <Typography
            sx={{
              color: "#292929",
              fontWeight: "800",
              marginRight: "3px",
            }}
          >
            {title}:
          </Typography>
        </Box> */}
        <Typography sx={{ color: "#292929" }}>
          {!!text ? (
            <span dangerouslySetInnerHTML={{ __html: text }} />
          ) : (
            <>
              {" "}
              <span style={{ fontWeight: "800" }}> {title}: </span>
              <span dangerouslySetInnerHTML={{ __html: subtitle }} />
              {/* {subtitle} </span> */}
            </>
          )}
        </Typography>
      </Box>
    </Stack>
  );
}

export default VerticalDivider;
