import {
  Box,
  Button,
  Card,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";

function UpdateEmail({ handleClose, setNewEmail }) {
  function handleUpdateEmail() {
    handleClose();
  }
  return (
    <div>
      <Dialog open={true} sx={{ bgcolor: "white" }}>
        <Card p={2} sx={{ maxWidth: 400 }}>
          <Box p={2} className="">
            <Typography
              variant="h4"
              align="center"
              mb={1}
              fontSize={20}
              fontWeight={700}
              fontFamily={"Poppins"}
            >
              Update Your Profile
            </Typography>
            <Typography
              variant="body1"
              align="center"
              mb={2}
              fontSize={12}
              fontFamily={"Poppins"}
            >
              Don't miss out! Update your email NOW for exclusive access to
              quizzes & competitions!
            </Typography>

            <Formik
              initialValues={{
                name: "",
                email: "",
              }}
              onSubmit={(values) => {}}
            >
              {(values) => (
                <Form>
                  <Box mb={2}>
                    <Typography
                      variant="body1"
                      mb={1}
                      fontSize={12}
                      fontFamily={"Poppins"}
                    >
                      Name{" "}
                    </Typography>
                    <Field
                      required
                      name="name"
                      className="input input-bordered w-full"
                    />
                  </Box>
                  <Box mb={2}>
                    <Typography
                      variant="body1"
                      mb={1}
                      fontSize={12}
                      fontFamily={"Poppins"}
                    >
                      Email Address{" "}
                    </Typography>
                    <Field
                      required
                      name="email"
                      className="input input-bordered w-full"
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      mb={1}
                      fontSize={12}
                      fontFamily={"Poppins"}
                    >
                     Mobile Number
                    </Typography>
                    <Field
                      required
                      name="phone"
                      className="input input-bordered w-full"
                    />
                  </Box>

                  <Box
                    mt={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleUpdateEmail}
                    >
                      Update
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Card>
      </Dialog>
    </div>
  );
}

export default UpdateEmail;
