import { ArrowRight } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { IoMdClock } from "react-icons/io";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useBeginModule } from "../../../hooks/shhCourses/useCourses";
import { useNavigate } from "react-router-dom";

function convertMinutesToTimeString(minutes) {
  if (!minutes) return "";

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours}:${remainingMinutes.toString().padStart(2, "0")}`;
}
const convertDate = (isoDate) => {
  if (!isoDate) return isoDate || "";

  let timestamp = Date.parse(isoDate);
  let jsDate = new Date(timestamp);
  let fDate = new Intl.DateTimeFormat("en-uk", {
    dateStyle: "short",
    timeStyle: "short",
  });
  return fDate.format(jsDate);
};

const Assessment = ({ module, isLocked, index }) => {
  const [loadingModule, setLoadingModule] = useState(false);
  const navigate = useNavigate();
  const ccAuth = JSON.parse(sessionStorage.getItem("ccAuth") || "{}");
  const course = JSON.parse(sessionStorage.getItem("course"));
  const beginModule = useBeginModule();

  const handleViewModule = (module) => {
    return () => {
      const ccAuth = JSON.parse(sessionStorage.getItem("ccAuth") || "{}");
      const course = JSON.parse(sessionStorage.getItem("course"));
      const cohortParticipantId = sessionStorage.getItem("cohortParticipantId");
      const journeyCohortId = sessionStorage.getItem("journeyCohortId");
      const userId = ccAuth?.id;
      const courseId = course?.courseId;

      if (!userId || !cohortParticipantId || !journeyCohortId || !courseId) {
        toast.error("Missing required parameters");
        return;
      }

      setLoadingModule(true);
      beginModule.mutate(
        {
          userId,
          cohortParticipantId,
          journeyCohortId,
          courseId,
          moduleId: module?.moduleId,
          assignedModuleId: module?.assignedModuleId,
        },
        {
          onSuccess: (data) => {
            console.log(data);
            toast.success(data?.message);
            const updatedModule = { ...module, modulePath: data?.data };
            sessionStorage.setItem(
              "ssh_session_module",
              JSON.stringify(updatedModule)
            );
            setLoadingModule(false);
            navigate(
              `/qwiz/courses-and-learning-path/${journeyCohortId}/${courseId}/${module?.moduleId}`
            );
          },
          onError: (e) => {
            setLoadingModule(false);
            toast.error(e?.response?.data?.message || "Error starting module");
          },
        }
      );
    };
  };

  return (
    <div className="border rounded-lg p-4 shadow-md   bg-white transition duration-300 hover:shadow-lg">
      <div className=" text-black">
        <h3 className="vertical-timeline-element-title font-bold capitalize">
          Assessment {index}: {module.module.name}
        </h3>

        <Typography
          fontSize={12}
          fontWeight={500}
          className="flex text-gray-700 mt-2 items-center"
        >
          <IoMdClock className="w-4" /> &nbsp; Date Update:{" "}
          {convertDate(module?.updatedAt)}
        </Typography>
      </div>
      <section className="md:flex mt-3 mb-6 items-center">
        <Typography fontSize={12} className="flex text-gray-700 items-center">
          Attempts: {module?.numberOfAttempts || 0}
        </Typography>
        <Typography fontWeight={500} className="flex text-gray-700 ml-4 items-center">
          <IoMdClock className="w-4" /> &nbsp;
          {convertMinutesToTimeString(module?.module?.duration)}
        </Typography>
      </section>
      <section className="md:flex mt-3 mb-6 items-center">
        <span
          className={`flex font-medium text-xs px-5 py-2 capitalize border border-primary-foreground bg-primary-foreground 
    ${
      !module?.moduleResultStatus
        ? "border-gray-100 bg-gray-100 text-gray-500"
        : ""
    }
    ${
      module?.moduleResultStatus === "pass"
        ? "border-green-100 bg-green-100 text-green-700"
        : ""
    }
    ${
      module?.moduleResultStatus === "fail"
        ? "border-red-100 bg-red-100 text-red-700"
        : ""
    }`}
        >
          {module?.moduleResultStatus || "Not Started"}
        </span>
      </section>
      <div className="flex justify-end">
        <button
          disabled={isLocked}
          className={`mt-3 max-w-fit bg-[#61a641] text-white py-2 px-4 rounded-lg hover:bg-blue-300 transition`}
          onClick={handleViewModule(module)}
        >
          {loadingModule ? (
            <ClipLoader size={20} color="#ffffff" />
          ) : (
            <>
              Take Assessment
              <ArrowRight className="w-5" />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Assessment;
