import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";

// const BASE_URL = "http://192.168.0.175:4001" // ? to be modify to the ip of the network
// const BASE_URL = "https://fizzle.gamequiz.live";
const BASE_URL = "https://gameapi.9ijakids.com";
// const BASE_URL = "https://conduit.9ijakids.com/";

export const request = axios.create({
  baseURL: BASE_URL,
});
request.interceptors.request.use((config) => {
  const auth = sessionStorage.getItem("ccAuth");
  if (auth) {
    config.headers.Authorization = `Bearer ${JSON.parse(auth).token}`;
  }
  return config;
});

export const useFetchAllQuiz = () => {
  const fetchAllQuiz = async () => {
    const { data } = await request.get(`/trivia/quizzes`);
    return data;
  };
  const fetchAllQuizQuery = useQuery(
    ["fetch-all-games"],
    () => fetchAllQuiz(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllQuizQuery;
};
export const useFetchGames = (props) => {
  const { payload } = props;
  const fetchAllGames = async (payload) => {
    const { data } = await request.get(
      `/trivia/quiz-question/${payload?.quiz_id}`,
      payload
    );

    return data;
  };
  const fetchAllGamesQuery = useQuery(
    ["fetch-all-games", payload.quizId],
    () => fetchAllGames(payload),
    {
      enabled: !!payload?.quiz_id,
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllGamesQuery;
};

// GET AVAILABLE PLANS
export const useFetchPlans = () => {
  const fetchAllPlans = async () => {
    const { data } = await request.get(`/trivia/plans`);
    return data.plans;
  };
  const { data: fetchAllPlansQuery, isLoading } = useQuery(
    ["fetch-all-plans"],
    () => fetchAllPlans()
  );
  return { fetchAllPlansQuery, isLoading };
};
// FETCH LEADERBOARD
export const useFetchLeaderBoard = (quizID) => {
  const fetchLeaderboard = async () => {
    const { data } = await request.get(`/trivia/leaderboard/${quizID}`);
    return data;
  };
  const { data: fetchAllLeaderboardData, isLoading } = useQuery(
    ["fetch-all-leaderboard", { quizID }],
    () => fetchLeaderboard(quizID),
    {
      refetchOnWindowFocus: false,
    }
  );
  return { fetchAllLeaderboardData, isLoading };
};

export const useCheckSubscribeStatus = (number) => {
  const CheckSubscribeStatusAPI = async (number) => {
    const { data } = await request.post(`/trivia/check-sub`, {
      user_passport: number,
    });
    return data;
  };
  const CheckSubscribeStatusQuery = useQuery(
    ["check sub status", number],
    () => CheckSubscribeStatusAPI(number),

    {
      refetchOnWindowFocus: false,
    }
  );

  return CheckSubscribeStatusQuery;
};

// SUBSCRIPTION ACTIVITY.

export const useSubscriptionActivity = ({ userid }) => {
  const fetchSubscriptionActivity = async () => {
    const { data } = await request.get(
      `/trivia/subscription-history/${userid}`
    );
    return data?.subActivities;
  };
  const fetchAllSubscriptionActivity = useQuery(
    ["fetch-all-leaderboard", { userid }],
    () => fetchSubscriptionActivity(userid),
    {
      refetchOnWindowFocus: false,
    }
  );

  return fetchAllSubscriptionActivity;
};

//FETCH ALL BANKS
export const useFetchAllBanks = () => {
  const fetchAllBanks = async () => {
    const { data } = await request.get(`/trivia/bank/list`);
    return data.banks;
  };
  const fetchAllAvailableBank = useQuery(["fetch-all-Banks"], () =>
    fetchAllBanks()
  );
  return fetchAllAvailableBank;
};

//FETCH USER ACCOUNT DETAIL
export const useFetchUserAccount = ({ userid }) => {
  const fetchAllAddedAccount = async () => {
    const { data } = await request.get(`/trivia/account/${userid}`);
    return data;
  };
  const fetchAllUsersAddedAccount = useQuery(["fetch-all-users-account"], () =>
    fetchAllAddedAccount()
  );
  return fetchAllUsersAddedAccount;
};

export const useFetchProfile = (userPassport) => {
  const fetchProfile = async () => {
    try {
      // const { data } = await request.get(`/telco/user/profile/${userPassport}`);
      const { data } = await request.get(`/trivia/get-profile/${userPassport}`);
      // console.log(" FETCH DATA", data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const queryProfile = useQuery(
    ["profile", userPassport],
    () => fetchProfile(),
    {
      refetchOnWindowFocus: false,
    }
  );
  // console.log("00h", data);
  return queryProfile;
};
export const useFetchStreak = (userPassport) => {
  // console.log(userPassport);

  const fetchStreak = async () => {
    try {
      const { data } = await request.get(
        `/telco/streak?user_passport=${userPassport}`
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log({ error: error.response || error });
    }
  };

  const queryStreak = useQuery(["streak", userPassport], () => fetchStreak(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  return queryStreak;
};

export const useFetchRewards = (userID) => {
  const fetchAllQuiz = async (userID) => {
    const { data } = await request.get(`/trivia/my-winning/${userID}`);
    return data;
  };
  const fetchAllQuizQuery = useQuery(
    ["fetch-reward", userID],
    () => fetchAllQuiz(userID),
    {
      refetchOnWindowFocus: false,
    }
  );
  return fetchAllQuizQuery;
};

// get users number 
export const useGetUsersNumber = () => {
  const fetchUsersNumber = async () => {
    const { data } = await axios.get(`http://mobi.9ijakids.com/send-number.php`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    console.log("fetch", data)
    return data;
  };

  return useQuery("fetch-reward", fetchUsersNumber, { refetchOnWindowFocus: false });
};

export const useFetchTrialStatus = (payload) => {
  const fetchStatus = async( ) =>{
    const { data } = await request.post(`/trivia/subscription-status`, payload)
    return data
  }

  const queryStatus= useQuery(["fetch-trial-status", payload?.number], fetchStatus)
return queryStatus
}