import React from "react";
import {
  useGetAssignedCourses,
  useGetAssignedCourseModule,
} from "../../hooks/shhCourses/useCourses";
import { useMutation } from "react-query";
import { request } from "../../hooks/api/fetch";
import { useCourseModule } from "./context/CourseModuleContext";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./components/ProgressBar";
import { ClipLoader } from "react-spinners";

const CoursesIntegration = () => {
  const navigate = useNavigate();
  const { courseModules, setCourseModules } = useCourseModule();

  const ccAuth = JSON.parse(sessionStorage.getItem("ccAuth") || "");

  const { data, isLoading, error } = useGetAssignedCourses(ccAuth?.id);

  const handleCardClick = (course) => {
    console.log(course, "course");
    sessionStorage.setItem("course", JSON.stringify(course));
    navigate(
      `/qwiz/courses-and-learning-path?cohortParticipantId=${sessionStorage.getItem(
        "cohortParticipantId"
      )}&journeyCohortId=${sessionStorage.getItem("journeyCohortId")}`
    );
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader className="bg-[#F07404] border-white" />
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;

  if (data) {
    sessionStorage.setItem("journeyCohortId", data?.journeyCohortId);
    sessionStorage.setItem("cohortParticipantId", data?.cohortParticipantId);
  }

  const CourseCard = ({ course }) => {
    const percentage =
      ((course?.numberOfCompletedModules +
        course?.numberOfCompletedAssessments) /
        (course?.numberOfModules + course?.numberOfAssessments)) *
        100 || 0;
    return (
      <div
        className={`border rounded-lg p-4 shadow-md  w-full bg-white transition duration-300 hover:shadow-lg`}
      >
        <h3 className="text-lg font-semibold text-gray-800 mb-6">
          {course.courseName}
        </h3>
        <div className="grid grid-cols-2 gap-4">
          <p className="text-sm font-normal text-gray-600">
            {course.numberOfModules}{" "}
            {course?.numberOfModules > 1 ? "modules" : "module"}s
          </p>
          <p className="text-sm font-normal text-gray-600">
            {course.numberOfCompletedModules}{" "}
            {course?.numberOfCompletedModules > 1 ? "modules" : "module"}{" "}
            completed
          </p>
          <p className="text-sm font-normal text-gray-600">
            {course.numberOfAssessments}{" "}
            {course?.numberOfAssessments > 1 ? "assessments" : "assessment"}
          </p>
          <p className="text-sm font-normal text-gray-600">
            {course.numberOfCompletedAssessments}{" "}
            {course?.numberOfCompletedAssessments > 1
              ? "assessments"
              : "assessment"}{" "}
            completed
          </p>

          {/* Status Indicator */}
          <span
            className={`inline-block text-xs font-medium px-3 py-2 max-w-fit rounded-sm mt-2 ${
              course.courseStatus !== null
                ? "text-[#61a641] bg-green-100"
                : "text-gray-500 bg-gray-200"
            }`}
          >
            {course.courseStatus !== null ? "In Progress" : "Not Started"}
          </span>
        </div>
        <section>
          {/* Progress Bar */}
          <ProgressBar progress={isNaN(percentage) ? 0 : percentage} />
          <p className="text-right text-xs text-gray-600 mt-1">
            {isNaN(percentage) ? 0 : percentage}% completed
          </p>

          {/* Take Course Button */}
        </section>
        <div className="flex justify-end">
          <button
            onClick={() => handleCardClick(course)}
            className="mt-3 max-w-fit bg-[#61a641] font-normal text-sm text-white py-2 px-4 rounded-lg hover:bg-green-300 transition"
          >
            {percentage > 0 ? "Continue" : "Take Course"}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {data &&
        data?.courseData?.map((course) => (
          <CourseCard key={course.courseId} course={course} />
        ))}
    </div>
  );
};

export default CoursesIntegration;
