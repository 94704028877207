import { ArrowLeft } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from '../../components/layout/AuthLayout';

const ViewModule = () => {

    const navigate = useNavigate();
    const course = JSON.parse(sessionStorage.getItem("course"));
    const currentModule = JSON.parse(sessionStorage.getItem("ssh_session_module"))
    const cohortParticipantId = sessionStorage.getItem("cohortParticipantId");
    const journeyCohortId = sessionStorage.getItem("journeyCohortId");

    
  return (
    <AuthLayout data-testid="authLayout">
    <section className="p-4 w-full h-screen bg-white border-t border-gray-100 shadow">
    <div className="flex w-full gap-3 mb-3 items-center justify-start">
      <Link
        className="flex px-5 max-w-[300px] w-full py-2 bg-white border shadow border-gray-100 items-center justify-start gap-1"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft className="w-4" />
        <Typography fontSize={12}
          fontWeight={500}>
          Back to {course?.courseName}
        </Typography>
      </Link>
      <div
        className="h-[42px] flex items-center justify-center text-white text-center w-full"
        
      >
        <Typography fontSize={12}
          fontWeight={500}>
      
          {currentModule?.module?.name} 
        </Typography>
      </div>
    </div>

    <iframe
      className="h-[88vh] w-full border border-gray-200 p-1"
      src={`${currentModule?.modulePath}`} 
    />
  </section>
  </AuthLayout>
  )
}

export default ViewModule