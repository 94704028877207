import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Box, Button, Typography } from "@mui/material";
import Image from "material-ui-image";
import boyIcon from "../../../assets/pointing-bubbles.png";
import Layout from "../../components/layout/Layout";
import { useUpdateProfile } from "../../hooks/api/post";
function Welcome() {
  const navigate = useNavigate();
  const updateProfileApi = useUpdateProfile();
  const userProfile = JSON.parse(sessionStorage.getItem("ccAuth"));
  function updateProfile() {
    updateProfileApi.mutate(
      {
        userId: userProfile.id,
        data: {
          name: userProfile.name,
          display_name: userProfile.displayName,
          avatar: userProfile.avatar,
          first_login: false,
        },
      },
      {
        onSettled: () => {
          navigate("../homepage");
        },
      }
    );
  }
  return (
    <Layout>
      <Container sx={{ paddingTop: "20px" }}>
        <Box>
          <Typography variant="h3" fontSize={20} fontWeight={900} mb={4}>
            Welcome, Qwiz Stars!
          </Typography>
          <Typography variant="body1" fontSize={12} fontWeight={900} mb={3}>
            Get ready to embark on an exciting journey of fun and enriching
            learning across various subject matters knowledge. Expand your
            knowledge and sharpening your critical thinking skills.{" "}
          </Typography>
          <Typography variant="body1" fontSize={12} fontWeight={900} mb={3}>
            {" "}
            Challenge your brain, learn something new, enjoy the experience, and
            stand a chance to win exciting cash prizes!{" "}
          </Typography>
          {/* <Typography variant="body1" fontSize={12} fontWeight={900} mb={3}>
            Get ready to engage your brain, learn something new and, have fun
            while doing it.
          </Typography> */}
        </Box>
        <Box>
          <Image src={boyIcon} color="transparent" />
        </Box>
        <Box mt={8}>
          {/* <Link to="../homepage"> */}
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={updateProfile}
          >
            Let's Get Started
          </Button>
          {/* </Link> */}
        </Box>
      </Container>
    </Layout>
  );
}

export default Welcome;
