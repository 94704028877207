import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function Logout() {
  const navigate = useNavigate();
  function performLogout() {
    let confirmLogout = window.confirm("Are you sure you want to log out");
    if (confirmLogout) {
      sessionStorage.clear();
      localStorage.removeItem("rememberMe");
      // localStorage.setItem("hideHelp", "false");
      navigate("/");
    } else {
      window.history.go(-1);
    }
  }
  useEffect(() => {
    performLogout();
    return () => performLogout;
  }, []);
  return <>Logging Out...</>;
}
