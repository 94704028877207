import React from "react";
import { Box, Container, Typography } from "@mui/material";
import "@fontsource/poppins";
import Image from "material-ui-image";
import qwizLogo from "../../../assets/qwiz-logo.png";
import styles from "./Layout.module.css";
function Layout({ children }) {
  return (
    <div>
      <Header />
      <Container disableGutters className={styles.container}>
        <Box className={styles.content}>{children}</Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Layout;
export function Header() {
  return (
    <Box pt={1}>
      {/* support header */}
      <Box sx={{ display: "flex", justifyContent: "center" }} mb={1}>
        <Box width={62}>
          <Image src={qwizLogo} color="transparent" />
        </Box>
      </Box>
      <Box
        height={40}
        sx={{
          backgroundColor: "#F29F06",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          color="#000"
          align="center"
          fontSize={12}
          sx={{
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          For support or enquiries, please contact:{" "}
          <a
            href="https://wa.me/message/GBEFBYHAWUIRH1"
            target="_blank"
            className={styles.link}
          >
            09157504476
          </a>
        </Typography>
      </Box>
    </Box>
  );
}
export function Footer() {
  return (
    <Box py={4}>
      <Typography
        variant="h6"
        align="center"
        fontSize={12}
        sx={{
          fontFamily: "'Poppins', sans-serif",
          color: "#333333",
        }}
      >
        © 2023. Qwiz - powered by 9ijakids
      </Typography>
    </Box>
  );
}
