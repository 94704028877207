import CryptoJS from "crypto-js";
const useDecrypt = () => {
  const key = "Just9ijaKidsEngr149.";
  const decrypt = (word) => {
    var bytes = CryptoJS.AES.decrypt(word, key);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);

    return originalText;
  };
  return decrypt;
};

export default useDecrypt;
