import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetchPlayGameUrl } from "../../hooks/playGame/usePlayGame";

function PlayGameFrame() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const gameID = searchParams.get("id");
  const ccAuth = JSON.parse(sessionStorage.getItem("ccAuth") || "");

  const playGame = useFetchPlayGameUrl({ user_id: ccAuth?.id, id: gameID });
  const navigateToHomepage = () => navigate("/qwiz/homepage");
  if (!gameID || playGame.isError || playGame.data?.success === false) {
    navigate("/qwiz/homepage");
  }
  return (
    <div className="h-[100vh] p-4 flex flex-col justify-center items-center">
      <div className="w-full bg-white p-4 flex justify-end">
        <button
          className="btn btn-error btn-sm max-w-max"
          onClick={navigateToHomepage}
        >
          X
        </button>
      </div>
      <iframe
        // src="https://www.qwizfun.com/9ijakids-event-games/9ijakids_Election_Quiz/index.html?Expires=1719418151&Key-Pair-Id=K2T44YVPCWGFDB&Signature=XQRe9U-JMS3kBI8Zpl2mxQ6ghaYMNXSsD18lK3nBDxa9KiXiG9MuthkY~Pp5d7MNZJ8Fk6vfYSowbVGmLIFDE0ZMFcMAZDYhWy93nf9pqj97T7QHo3WlBk2Ayg-coYDOeajfp0JgVT0MQGsgYYWskyax9basdEECxQxXRKyqNuP5s~z7n3KdRZoFkGoBS5A5RwsrTsjTYB1F7jtnC~XzEg8iEjqa6~J4oHtqTZOrrTqR-SWXGtfwjRdzdwj39VcJQYBcD-vCAl1yDbp4m5CdmQUI9ESsRIo8PBFwQzmPFj6wQdr8FuQe3~ZODfV2M-Eq3t003vtu4kVfGURhNJ18Pg__?user_id=92a8a23a-551b-4f91-8d2a-c344836f40fa&username=additional_white_bugs&quiz_id=099b0c7c-7076-4d8a-927f-33da6c63e206&game_id=099b0c7c-7076-4d8a-927f-33da6c63e206&number_of_question=10&number_of_live=9&number_of_plays=23"
        src={playGame.data?.data}
        title="gameplay frame"
        className="w-full h-[90%]"
      />
    </div>
  );
}

export default PlayGameFrame;
