import React from 'react'
import coinIcon from "../../../assets/icons/coin.png"
function EarnPointBanner() {
  return (
    <div className='flex mt-4 mx-2 rounded-2xl p-4 text-white bg-[#F07404] justify-between items-center'>
    <div>
      <h3>Be a Winner</h3>
     <p>Click here to start </p>
      </div>
      <div>
        <img src={coinIcon} alt="coin" />
      </div>
      </div>
  )
}

export default EarnPointBanner
