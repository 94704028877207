import React from "react";
import { Link } from "react-router-dom";

function IconCard({ title, details, icon, link }) {
  return (
    <Link to={link}>
      <div className="stat">
        <div className="stat-figure text-primary">{icon}</div>
        <div className="stat-title font-semibold">{title}</div>
        <div className="stat-value text-[#61a641c4]  text-center text-sm font-normal">
          {details}
        </div>
        {/* <div className="stat-desc">21% more than last month</div> */}
      </div>
    </Link>
  );
}

export default IconCard;
