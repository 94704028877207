import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import TopNav from "../TopNav/TopNav";
import VerticalDivider from "../verticalDividerCard/VerticalDivider";
import Footer from "../../pages/landingPage/components/Footer";
import AuthLayout from "../../Mobi2.0/components/layout/AuthLayout";

function PagePanel({ title, options }) {
  return (
    <AuthLayout>
      <Box
        sx={{
          background: "url(/images/starBG.png)",
          backgroundColor: "#143337",
          color: "white",
          marginTop: "20px",
          borderRadius: "20px",
        }}
      >
        {/* <Box sx={{ backgroundColor: "white" }}>
          <TopNav />
        </Box> */}
        <Typography
          variant="h4"
          align="center"
          pt={3}
          sx={{ margin: "0px 0 24px", color: "white" }}
        >
          {title}
        </Typography>
        <Stack
          sx={{
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "20px",
          }}
        >
          {options?.map((option) => (
            <VerticalDivider sn={option?.sn} text={option?.text} />
          ))}
          {/* <VerticalDivider
          sn="1"
          text="You have the opportunity to play multiple times and aim for the top spot on the leaderboard. Your position on the leaderboard is determined by your highest score."
        /> */}
        </Stack>
        <Footer />
      </Box>
    </AuthLayout>
  );
}

export default PagePanel;
