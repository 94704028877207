import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
function TrialCard({ handleClick, isExpired, expiryDate }) {
  return (
    <Box bgcolor="#FCECCD" p={2} mt={{ md: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "7px",
        }}
      >
        {!isExpired ? (
          <Typography variant="body1" fontSize={12} align="center">
            Start your{" "}
            <span style={{ fontWeight: 900 }}>FREE 1-day Trial </span>
            today to unlock and enjoy all games. `
          </Typography>
        ) : (
          <Typography variant="body1" fontSize={12} align="center">
            Your free trial ended on{" "}
            <span style={{ fontWeight: 900 }}>
              {dayjs(expiryDate).format("DD/MM/YYYY H:m")}
            </span>{" "}
            Subscribe now to keep enjoying fun quizzes
          </Typography>
        )}{" "}
        <Box sx={{ width: 200 }}>
          <Link to="http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web">
            <Button
              size="large"
              onClick={handleClick}
              fullWidth
              variant="contained"
              color="error"
            >
              <Typography fontSize={14} color="white">
                {isExpired ? "Subscribe" : "  Start Trial"}
              </Typography>
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default TrialCard;
